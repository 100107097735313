.picBg3 {
  background-image: url('../../../img/records.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 60vh;
}


.picBgShop{
  background-image: url('../../../img/pedal-row.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, .7);
}

.guitarsHeader {
  padding-top: 6%;
}
.ampsPedalsHeader {
  padding: 10% 0;
}

.ampsHeader {
  padding: 10% 0;
}

.entrance-img {
  padding-top: 5%;
  width: 100%;
}

.controlledCarouselHeader {
  margin-left: 5%
}

.showroom-item {
  width: 100%
}

.amps-section { 
  margin-top: 5%;
  background-color: #eee;
  padding-bottom: 5%;
}

@media(max-width:991px) {
  .showroom-item {
    padding-top: 20px;
  }
}

@media (max-width: 767px){
  .picBg3 {
    background-attachment: scroll
  }
  .picBgShop {
    background-attachment: scroll;
  }
}