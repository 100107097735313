.Footer {
    background-color: #587789;
    color: white;
    padding: 15px 0;
}

.a3-footer{
    text-align:center;
    font-family: Conthrax
}

.contact-icons {
    line-height:35px;
}

.footer-poweredBy {
  font-size: 12px !important;
  display: inline-block;
}