.videoBg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-color:#587789;
  overflow: hidden;
}

.picBg2{
  background-image: url('../../../img/miles_hanging_guitars.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, .7);
}
.schedulingBg{
  background-image: url('../../../img/scheduling.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(255, 255, 255, .7);
}

.picBg4{
  background-image: url('../../../img/lessons.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picBg5{
  background-image: url('../../../img/studio.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picBg6 {
  display: none;
  background-image: url('../../../img/storefront.jpg');
  background-size: cover;
  background-attachment: scroll;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 335px;
  align-items: center;
  justify-content: center;
}

.service-col {
  margin-top: 5%;
  padding: 15px;
  color: white;
}

video {
    width: 100%;
}

.map-box {
  min-height:420px;
  background-size: cover;
}

.g-map {
  width: 100%;
  min-height:340px;
}

.connecting-world-section {
    width: 100%;
}

.about-section {
    padding-top: 2%;
    padding-bottom: 5%
}

h2 {
    padding: 5% 0 5% 0;
}

h3 {
  padding: 5% 0 5% 0;
}

.service-wrapper {
  background-color: rgba(0, 0, 0, 0.65);
  width:100%;
  height: 100%;
}

.service-summary {
    padding: 0 0 5% 0;
}

h4.service-title {
    width:100%;
}

.special-text {
  font-style: italic;
  color: #ae7890 !important;
  font-weight:700;
  font-size: 19px
}

a.special-text:hover {
  color: #ae7890 !important;
}

.history-section {
  padding-top: 10%
}

.tel {
  font-size: 18px;
  color: #587789 !important;
}
.tel-footer {
  font-size: 18px;
  color: #d897b4 !important;
}

a.tel-footer:hover {
  color: #ffffff !important;
}

.call-to-action-btn {
    background-color:#ae7890;
    border: 1px solid #587789;
}

.call-to-action-btn:hover{
    background-color:#304857;
}

.home-about {
  background-color: #587789;
  color:white;
}

.container-fluid {
  padding: 0px;
}

.home-img {
  width: 100%;
  height: auto;
  padding-bottom: 25px;
}


.row {
  margin-right: 0px
}

@media (max-width: 767px){
  .videoBg {
    display:none;
  }
  .picBg6 {
    display: flex;
  }
  .home-img-col {
    display: none; 
  }
  .picBg4 {
    background-attachment: scroll
  }
  .picBg2{
    background-attachment: scroll;
  }
  .picBg5{
    background-attachment: scroll
  }
}