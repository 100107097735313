.picBgStudio{
  background-image: url('../../../img/headphones.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 60vh;
}
@media (max-width: 767px){
  .picBgStudio {
    background-attachment: scroll
  }
}