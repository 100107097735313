.picBg{
  background-image: url('../../../img/guitars.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 60vh;
}

.card-title{
    text-align:center;
}

.TeacherCard {
  padding-bottom: 5%
}
@media (max-width: 767px){
  .picBg {
    background-attachment: scroll
  }
}