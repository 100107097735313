nav {
    background-color:#587789a8;
}

.logo-img {
    width:40%;
}

a {
    color: white !important;
    font-size: 20px;
}

a:hover {
    color: #b46b8c !important;
}

.navbar {
    padding: 0;
}

.Header {
    width:100%;
}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

  }

  button.navbar-toggler-icon {
    padding-right: 20px;
  }
  
  .custom-toggler.navbar-toggler {
    border-color: rgb(255, 255, 255);
  } 

  nav{
      padding-right:10px !important;
      padding-left:10px !important;
  }