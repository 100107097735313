.guitarBrand {
  max-height: 200px;
  width: auto;
  height: 70%;
  margin: 0 auto;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
  margin-top:-50%
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 45px;
  color: #000000;;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 45px;
  color: #000000;
}

.carousel-indicators>li {
  background-color: black;
}
